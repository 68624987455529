<template>
<div>
  
  <!-- <el-dialog
    :title="$tc('message.terms_of_use', 2)"
    :visible.sync="showModalTermsUse"
  > -->
  <dialog-modal :show.sync="showModalTermsUse" :title="$tc('message.terms_of_use', 2)">
    <span>
      <TermsUse/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showModalTermsUse = false">{{ $t('action.close') }}</el-button>
    </span>
  <!-- </el-dialog> -->
  </dialog-modal>

  <!-- <el-dialog
    title="Privacy Policy"
    :visible.sync="showModalPrivacyPolicy"
  > -->
  <dialog-modal :show.sync="showModalPrivacyPolicy" title="Privacy Policy">
    <span>
      <PrivacyPolicy/>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showModalPrivacyPolicy = false">{{ $t('action.close') }}</el-button>
    </span>
  </dialog-modal>
  <!-- </el-dialog> -->
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PrivacyPolicy from './PrivacyPolicy';
import TermsUse from './TermsUse';
import DialogModal from '../DialogModal.vue';

// import {
//   Dialog as ElDialog,
// } from "element-ui";

export default {
  name: 'PoliciesAndTerms',
  components: {
    PrivacyPolicy,
    TermsUse,
    DialogModal,
    // ElDialog
  },
  computed: {
    ...mapState('utils',
    [
      'showTermsUse',
      'showPrivacyPolicy'
    ]),
    showModalTermsUse: {
      get() {
        return this.showTermsUse;
      },
      set(value) {
        this.setShowTermsUse(value);
      }
    },
    showModalPrivacyPolicy: {
      get() {
        return this.showPrivacyPolicy;
      },
      set(value) {
        this.setShowPrivacyPolicy(value);
      }
    }
  },
  methods: {
    ...mapMutations('utils', [
      'setShowTermsUse',
      'setShowPrivacyPolicy'
    ]),
  }
}
</script>
