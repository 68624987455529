<template>
  <div v-if="errorsArray.length || errorsMessage">
    <div class="alert alert-danger errors">
      <div v-if="errorsMessage && !errorsArray.length" v-html="errorsMessage"></div>
      <div v-if="errorsArray">
        <ul v-for="(errors, i) in errorsArray" :key="i">
          <li v-for="(error, x) in errors" :key="x">{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorsArea",
  props: {
    errorsData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    errorsArray() {
      if (this.errorsData.errors && Object.keys(this.errorsData.errors)) {
        return this.errorsData.errors;
      }

      return [];
    },
    errorsMessage() {
      if (this.errorsData.message) {
        return this.errorsData.message;
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.errors {
  padding-left: 20px;
}
</style>
