<template>
<div v-html="$t('terms')"></div>
</template>

<script>

export default {
  name: 'TermsUse',
  mounted() {
    // eslint-disable-next-line
    // ga('set', 'page', '/terms_use.html');
    // eslint-disable-next-line
    // ga('send', 'pageview', '/terms_use');
  }
}
</script>
