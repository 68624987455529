<template>
    <div v-if="show" class="modal-overlay" @click="handleOverlayClick">
      <div class="modal-container" @click.stop>
        <dialog ref="modal" class="custom-modal" :open="show">
          <div class="modal-content">
            <header class="modal-header">
              <h2>{{ title }}</h2>
              <button @click="close" class="close-btn">&times;</button>
            </header>
            <div class="modal-body">
              <slot></slot>
            </div>
            <footer class="modal-footer">
              <button @click="close" class="btn">Fechar</button>
            </footer>
          </div>
        </dialog>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Título da Modal'
      },
      show: {
        type: Boolean,
        default: false
      },
      closeOnClickOutside: {
        type: Boolean,
        default: true // Define se o modal deve fechar ao clicar fora dele
      }
    },
    watch: {
      show(value) {
        if (value) {
          this.$refs.modal.showModal();
        } else {
          this.$refs.modal.close();
        }
      }
    },
    methods: {
      close() {
        this.$emit('update:show', false); // Atualiza o valor da prop 'show' para fechar o modal
      },
      handleOverlayClick() {
        if (this.closeOnClickOutside) {
          this.close(); // Fecha o modal se a prop closeOnClickOutside for true
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  /* Overlay que cobre a tela, com scroll se necessário */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Fundo translúcido */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Centraliza o modal no topo */
    overflow-y: auto; /* Ativa o scroll no overlay */
    z-index: 1000; /* Garante que o overlay fique por cima de outros elementos */
    padding: 20px; /* Espaçamento ao redor do modal */
  }
  
  .modal-container {
    width: 100%; /* Garante que o container se ajuste ao overlay */
    display: flex;
    justify-content: center; /* Centraliza horizontalmente o modal */
    border-radius: 1em;
  }
  
  .custom-modal {
    max-width: 95%;
    margin: auto; /* Mantém o modal centralizado */
    border: none;
    padding: 0;
    border-radius: .5em;
    overflow: hidden;

    @media (min-width: 768px) {
        max-width: 50%;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f4f4f4;
  }
  
  .close-btn {
    font-size: 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-footer {
    padding: 10px;
    text-align: right;
    background-color: #f4f4f4;
  }
  
  .btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  </style>
  