<template>
<div v-html="$t('policy')"></div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  mounted() {
    // eslint-disable-next-line
    // ga('set', 'page', '/privacy_policy.html');
    // eslint-disable-next-line
    // ga('send', 'pageview', '/privacy_policy');
  }
}
</script>
